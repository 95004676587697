// Imports
// ------
import styled, { css } from 'styled-components';
import TransitionLink from 'gatsby-plugin-transition-link';
import { breakup, Section, Div, P } from '@tackl';
import { h1Styles } from '@type';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
	`
);

export const Line = styled.span(
	(props) => css`
		position: absolute;
		top: 0;
		left: 2.4rem;
		right: 2.4rem;
		height: 2px;
		background: ${props.theme.white}20;
		transform: scaleX(0);
		transform-origin: left center;

		${breakup.medium`
				left: calc(8.333vw + 1.2rem);
				right: calc(8.333vw + 1.2rem);
			`}

		${breakup.large`
				left: calc(8.333vw + 1.8rem);
				right: calc(8.333vw + 1.8rem);
			`}
	`
);

export const Sweater = styled(Div)(
	(props) => css`
		//-
	`
);

export const Content = styled(Div)(
	(props) => css`
		//-
	`
);

const sharedHeading = (props) => css`
	${h1Styles}
	display: inline-block;
`;

export const Title = styled.h2(
	(props) => css`
		${sharedHeading}
	`
);

export const PageLink = styled(TransitionLink)(
	(props) => css`
		${sharedHeading}
		${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}

		position: relative;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

		&:hover {
			${breakup.large`
				&:after {
					background-position: -200%;
				}
			`}
		}

		&:after {
			${breakup.large`
				content: attr(data-text);
				position: absolute;
				z-index: 12;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				background: linear-gradient(
					to right,
					rgba(255, 255, 255, 0) 0%,
					rgba(255, 255, 255, 1) 50%,
					rgba(255, 255, 255, 0) 100%
				);
				background-size: 200% 100%;
				background-position: 200%;
				background-repeat: no-repeat;

				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: background-position 1s ease-in-out;
			`}
		}
	`
);

export const Footer = styled(P)(
	(props) => css`
		margin: 0 0 ${props.theme.padS} 2.4rem;
		opacity: 0.4;
		pointer-events: none;
		user-select: none;

		${breakup.medium`
			margin: 0 0 6rem 3.6rem;
		`}

		${breakup.large`
			margin: 0 0 6rem 6rem;
		`}
	`
);
