// Imports
// ------
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
// Styles
// ------
import {
	Jacket,
	Sweater,
	Content,
	Title,
	PageLink,
	Footer,
	Line,
} from './styles';

// Component
// ------
gsap.registerPlugin(ScrollTrigger);

function CallToAction() {
	// NOTE • MIXPANEL
	const mixpanel = useMixpanel();

	// NOTE • Breakpoints
	const bp = useBreakpoint();

	// NOTE • Refs
	const target = useRef();
	const trigger = useRef();

	// NOTE • Year
	const date = new Date();
	const year = date.getFullYear();

	// NOTE • Page Transtion Props
	const pageTrans = {
		to: `/contact/`,
		className: 'cursor-hover',
		exit: { length: 1 },
		entry: { length: 1, delay: 1 },
	};

	// NOTE • Handle CLick
	const handleClick = () => {
		mixpanel.track(`Call to Action`);
	};

	// NOTE • Onload: Scroll Magic!
	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(target.current, {
				scaleX: 1,
				ease: 'none',
				scrollTrigger: {
					trigger: trigger.current,
					start: '0% 100%',
					end: '100% 100%',
					scrub: 0.5,
					markers: false,
				},
			});
		}, 250);

		return () => {
			clearTimeout(timer);
		};
	}, []);

	return (
		<Jacket ref={trigger}>
			<Line ref={target} />

			<Sweater pad>
				<Row isExpanded>
					<Column
						small={12}
						medium={10}
						pushOnMedium={1}
						large={10}
						pushOnLarge={1}
						mpad>
						<Content>
							<Title>Put your mind {bp.large ? null : 'totally '}at ease</Title>
							<PageLink
								{...pageTrans}
								onClick={handleClick}
								data-text={`Contact us ${bp.medium ? 'today' : null}`}>
								Contact us {bp.medium ? 'today' : null}
							</PageLink>
						</Content>
					</Column>
				</Row>
			</Sweater>

			<Footer>&copy; Balbycare {year}</Footer>
		</Jacket>
	);
}

export default CallToAction;
